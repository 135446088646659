import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import Header from '../directives/header';
import { InputMask } from '@react-input/mask';
import Footer from '../directives/footer';
import Rightnav from '../directives/rightSidebar';
import Leftnav from '../directives/leftSidebar';
import toast, { Toaster } from 'react-hot-toast';
import banner from '../Assets/BC-newLogos.jpg'
import config from '../coreFIles/config';
import { UpgradeAccountAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const VipmemberShip = () => {
    // style
    const timeColor = `#${Domainstyle[0]?.time_color}`;
    const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
    const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
    const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
    const header_search_text = `#${Domainstyle[0]?.header_search_text}`
    const [form, setForm] = useState({
        name: '',
        cardNumber: '',
        expiryDate: '',
        cvv: ''
    });
    const [validationError, setValidationError] = useState({});

    // style end
    useEffect(() => {
        //upgrademembership()
    }, [])




    // image click base url
    const imgclick = () => {
        window.location.href = `${config.baseUrl}`;
    }
    const inputHandler = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const handleKeyDown = (e) => {
        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
            e.preventDefault();
        }
    };
    //    button submit
    const handlesubmit = () => {
        if (loginData) {
            toast.success("Kindly log in first to upgrade")
            setTimeout(() => {
                window.location.href = `${config.baseUrl}login`
            }, 1000)
        }
        else {
            console.log("you are login now")
        }
    }

    const formatDate = (str) => {
        const date = new Date(str);
        const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    };
    function validate(form) {
        let errors = {};
        let isValid = true;

        if (!form.name || form.name.trim() === "") {
            errors.nameError = "Name is required.";
            isValid = false;
        }


        const cardNumberRegex = /^\d{4} \d{4} \d{4} \d{4}$/;
        if (!form.cardNumber || !cardNumberRegex.test(form.cardNumber)) {
            errors.cardNumberError = "Card number must be 16 digits, formatted as '____ ____ ____ ____'.";
            isValid = false;
        }


        const expiryDateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        if (!form.expiryDate || !expiryDateRegex.test(form.expiryDate)) {
            errors.expiryDateError = "Expiration date must be in MM/YY format.";
            isValid = false;
        }


        const cvvRegex = /^\d{3}$/;
        if (!form.cvv || !cvvRegex.test(form.cvv)) {
            errors.cvvError = "CVV must be 3 digits.";
            isValid = false;
        }

        return { isValid, errors };
    }

    const upgrademembership = async () => {
        console.log(loginData.id)
        console.log(form)
        const { isValid, errors } = validate(form);
        if (!isValid) {
            setValidationError(errors);
        }
        if(loginData){
            const fromDate = formatDate(new Date());
             const res = await UpgradeAccountAction({ memberid: loginData.id,current_date:fromDate,form})
        if (res.success) {
            toast.success(res.msg);
            // setInterval(() => {
            //     window.location.href=`${config.baseUrl}`
            // }, 2000);
        }


        }

    }
    return (
        <>

            <Header />

            <div className="add-section mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <Leftnav />
                        </div>

                        <div className="col-lg-6">

                            {!document.cookie.includes("loginSuccessMember") ?
                                <div className="classification-section">
                                    <h2 className="page-heading" style={{ background: headerMenuColor }}>VIP Membership</h2>

                                    <div className="table-responsive table-striped">
                                        <p>Become a VIP Member for 12 months and you can post as many ads as you like with no restrictions*<u><b>( Excluding 'Personals' section )</b></u> on all our websites with the same login:</p>
                                    </div>
                                    <div className='img'>
                                        <div className='col-lg-12'>
                                            <img id='bannervip' style={{ width: "600px", margin: "10px" }} src={banner} alt='internet error' onClick={imgclick} />
                                        </div>
                                    </div>
                                    <div className="table-responsive table-striped">For only $1499 VIP memberships will assist in helping our network grow , fund new features on the website and you will get priority notification of any coupon deals, special promotions and event invites.

                                        Upgrade today and post as many ads as you like to thousands of weekly visitors!!!
                                        * This offer is not available to advertisers in the 'Personals' section- Please contact us for VIP advertising in this section.
                                    </div>


                                </div>
                                : (
                                    <div className="classification-section">
                                        <h2 className="page-heading" style={{ background: headerMenuColor }}>VIP Membership</h2>
                                        <form onSubmit={upgrademembership}>
                                            <div className="bondiclassified-fromsection">
                                                <h2 className="page-heading">Card Detail</h2>
                                                <div className="catagoryfrom">
                                                    <div className="row">
                                                        {/* Name Field */}
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="name">Name <span className='text-danger'>*</span></label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='name'
                                                                    id='name'
                                                                    value={form.name}
                                                                    onChange={inputHandler}
                                                                />
                                                                {validationError.nameError && (
                                                                    <span className="text-danger">{validationError.nameError}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Card Number Field */}
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="cardNumber">Card Number <span className='text-danger'>*</span></label>
                                                                <InputMask
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='cardNumber'
                                                                    id='cardNumber'
                                                                    value={form.cardNumber}
                                                                    onChange={inputHandler}
                                                                    mask="____ ____ ____ ____"
                                                                    replacement={{ _: /\d/ }}
                                                                    placeholder="____ ____ ____ ____"
                                                                />
                                                                {validationError.cardNumberError && (
                                                                    <span className="text-danger">{validationError.cardNumberError}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* Expiry Date Field */}
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="expiryDate">Expiration Date <span className='text-danger'>*</span></label>
                                                                <InputMask
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='expiryDate'
                                                                    id='expiryDate'
                                                                    value={form.expiryDate}
                                                                    onChange={inputHandler}
                                                                    mask="__/__"
                                                                    replacement={{ _: /\d/ }}
                                                                    placeholder="__/__"
                                                                />
                                                                {validationError.expiryDateError && (
                                                                    <span className="text-danger">{validationError.expiryDateError}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        {/* CVV Field */}
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="form-group">
                                                                <label htmlFor="cvv">CVV <span className='text-danger'>*</span></label>
                                                                <InputMask
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='cvv'
                                                                    id='cvv'
                                                                    value={form.cvv}
                                                                    onChange={inputHandler}
                                                                    mask="___"
                                                                    replacement={{ _: /\d/ }}
                                                                    placeholder="___"
                                                                />
                                                                {validationError.cvvError && (
                                                                    <span className="text-danger">{validationError.cvvError}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>


                                )}
                            {!document.cookie.includes("loginSuccessMember") ?
                                <div className='payVip'>
                                    <a className="payNow" id='pay-now' onClick={handlesubmit} style={{ background: headerButtonColor }} >Pay Now</a>
                                </div> : (
                                    <div className='payVip'>
                                        <a className="payNow" id='pay-now' onClick={upgrademembership} style={{ background: headerButtonColor }} >Pay Now</a>
                                    </div>
                                )}
                        </div>

                        <div className="col-lg-3">
                            <Rightnav />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default VipmemberShip;