import './App.css';
import { useState,useEffect } from 'react';
import config from "./coreFIles/config";
import Cookies from 'js-cookie'
import Login from './component/login';
import Register from './component/register';
import ForgotPassword from './component/forgotpassword';
import Home from './component/home';
import Addetails from './component/addetails';
import Adslist from './component/adslist';
import SearchAds from './component/searchads';
import AllAdslist from './component/alladslist';
import AddAd from './component/addad';
import EditAd from './component/editad';
import ManageAd from './component/managead';
import About from './component/about';
import Advertise from './component/advertise';
import Help from './component/help';
import Terms from './component/terms';
import Privacy from './component/privacy';
import Profile from './component/profile';
import Contact from './component/contact';
import Favouritads from './component/favouritads';
import Searchhistory from './component/searchhis'
import TellaFriend from './component/tellafriend';
import Upgrade_ad  from './component/upgrade_ad';
import VipmemberShip from './component/vipmembership';

import { BrowserRouter, Route, Routes } from 'react-router-dom'
const Domainid = (!Cookies.get('domain_id')) ? [] : JSON.parse(Cookies.get('domain_id'));
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
	
	function App() {
		
		
		
		const [isVisible, setIsVisible] = useState(false);
		const ScrollToTop= () => {
			window.scrollTo({
			top: 0,
			behavior: "smooth"
			});
		};
		useEffect(() => {
			const toggleVisibility = () => {
			  if (window.pageYOffset > 500) {
				setIsVisible(true);
			  } else {
				setIsVisible(false);
			  }
			};
			window.addEventListener("scroll", toggleVisibility);
		
			return () => window.removeEventListener("scroll", toggleVisibility);

		
		}, []);
		
		
		return (
		
		<BrowserRouter>		
			<div className='main'>
				<Routes>									
					<Route path={`${config.baseUrl}login`} element={<Login />} />
					<Route path={`${config.baseUrl}register`} element={<Register />} />
					<Route path={`${config.baseUrl}forgot-password`} element={<ForgotPassword />} />
					<Route path={`${config.baseUrl}`} element={<Home />} />
					<Route path={`${config.baseUrl}ad/:id/:any`} element={<Addetails />} />
					<Route path={`${config.baseUrl}cat/:id/:any`} element={<Adslist />} />
					<Route path={`${config.baseUrl}cat/:id/action/all/:any`} element={<AllAdslist />} />
					<Route path={`${config.baseUrl}search/:title/:category/:state/:suburb`} element={<SearchAds />} />
					<Route path={`${config.baseUrl}addad`} element={<AddAd />} />
					<Route path={`${config.baseUrl}edit-ad/:id`} element={<EditAd />} />
					<Route path={`${config.baseUrl}manage-ads`} element={<ManageAd />} />
					<Route path={`${config.baseUrl}about`} element={<About />} />
					<Route path={`${config.baseUrl}advertise-with-us`} element={<Advertise />} />
					<Route path={`${config.baseUrl}help`} element={<Help />} />
					<Route path={`${config.baseUrl}terms-and-conditions`} element={<Terms />} />
					<Route path={`${config.baseUrl}privacy-policy`} element={<Privacy />} />
					<Route path={`${config.baseUrl}profile-update`} element={<Profile />} />
					<Route path={`${config.baseUrl}contact-us`} element={<Contact />} />
					<Route path={`${config.baseUrl}markfavouriteAd`} element={<Favouritads />} />
					<Route path={`${config.baseUrl}searchhistory`} element={<Searchhistory />} />
					<Route path={`${config.baseUrl}sharewithfriend`} element={<TellaFriend />} />
					<Route path={`${config.baseUrl}upgrade-ad/:id`} element={<Upgrade_ad/>}/>
					<Route path={`${config.baseUrl}vipmembership`} element={<VipmemberShip/>}/>
					
				</Routes>
				{isVisible && (
				<a href='#' className="scrollToTop"  onClick={ScrollToTop} style={{background:`#${Domainstyle[0]?.header_buttoncolor}`}} >Go To Top</a>)}
			</div>
		</BrowserRouter>
		);
	}


export default App;
