import React, { useEffect, useRef, useState } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from 'react-google-recaptcha';
import { InputMask } from '@react-input/mask';
import config from '../coreFIles/config';
import Header from '../directives/header';
import Leftnav from '../directives/leftSidebar';
import Rightnav from '../directives/rightSidebar';
import Footer from '../directives/footer';
import Cookies from 'js-cookie';
import { ContactusPostAction } from '../Action/action';

const loginData = !Cookies.get('loginSuccessMember') ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const Profile = () => {
    // style
    const timeColor = `#${Domainstyle[0]?.time_color}`;
    const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
    const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
    const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
    const header_search_text = `#${Domainstyle[0]?.header_search_text}`
    // style end
    const [spinloader, setspinloader] = useState(0);
    const [validationError, setvalidationError] = useState({});
    const recaptchaRef = useRef(null);

    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        msg: '',
        captchaValue:''
    });
    const inputHandler = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const inputPhoneHandler = (e) => {
        const { name, value } = e.target;
        setForm((old) => ({ ...old, [name]: value }));

    };



    const handleKeyDown = (e) => {
        if (!/^\d$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
        }
    };

    function validate() {
        let nameError = "";
        let emailError = "";
        let msgError = "";
        let phonenoError = ""
        let captchaValueError = "";
        if (form.name === '') nameError = "Name is required.";
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (form.email === '') {
            emailError = "Email is required.";
        } else if (!emailRegex.test(form.email)) {
            emailError = "Please enter a valid email address.";
        }
        if (form.msg === '') msgError = "Message is required.";
        if (form.phone === '') {
            phonenoError = "Phone no. required"
        }
        if (form.captchaValue === '') captchaValueError = "Captcha is required.";
        if (nameError || emailError || msgError || captchaValueError || phonenoError) {
            setvalidationError({
                nameError, emailError, msgError, captchaValueError, phonenoError
            });
            return false;
        } else {

            setvalidationError({
                nameError, emailError, msgError, captchaValueError, phonenoError
            });
            return true;
        }
    }

    // for captcha handle submit
    const onCaptchaChange = (value) => {
        setForm((prev) => ({ ...prev, captchaValue: value }));
    };

    const SubmitForm = async (e) => {
        e.preventDefault();
        const isValid = validate();
        if (!isValid) return;
        setspinloader(1);
        let res = await ContactusPostAction({ ...form });
        if (res.success) {
            toast.success(res.msg);
            window.location.reload()
        } else {
            toast.error(res.msg);
        }
        setspinloader(0);
    };


    return (
        <>
            <Header />
            <div className="add-section mb-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <Leftnav />
                        </div>
                        <div className="col-lg-6">
                            <Toaster />
                            <div className="classification-section">
                                <h2 className="page-heading" style={{ background: headerMenuColor }}> Contact Us </h2>
                                <form action='' method='post'>
                                    <div className="bondiclassified-fromsection">
                                        <div className="catagoryfrom">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name <span className='text-danger'>*</span></label>
                                                        <input type='text' className='form-control' name='name' id='name' onChange={inputHandler} value={form.name} />
                                                        <span className="text-danger">{validationError.nameError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email <span className='text-danger'>*</span></label>
                                                        <input type='text' className='form-control' name='email' id='email' onChange={inputHandler} value={form.email} />
                                                        <span className="text-danger">{validationError.emailError}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="phone">
                                                            Phone <span className='text-danger'>*</span>
                                                        </label>
                                                        <InputMask
                                                            type='text'
                                                            className='form-control'
                                                            name='phone'
                                                            id='phone'
                                                            value={form.phone}
                                                            onChange={inputPhoneHandler}
                                                            onKeyDown={handleKeyDown}
                                                            mask="____ ___ ___"
                                                            replacement={{ _: /\d/ }}
                                                            placeholder="____ ___ ___"

                                                        />
                                                        <span className="text-danger">{validationError.phonenoError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="msg">Message <span className='text-danger'>*</span></label>
                                                        <textarea className="form-control" name="msg" rows='5' id='msg' onChange={inputHandler} value={form.msg}></textarea>
                                                        <span className="text-danger">{validationError.msgError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="form-group">
                                                        <ReCAPTCHA
                                                            ref={recaptchaRef}
                                                            sitekey={config.site_key}
                                                            onChange={onCaptchaChange}
                                                        />
                                                        <span className="text-danger">{validationError.captchaValueError}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3 pdb-20">
                                                    {spinloader === 0 ? (
                                                        <button
                                                            type="button"
                                                            onClick={SubmitForm}
                                                            className="btn btn-primary login-btn"
                                                            style={{ background: headerButtonColor }}
                                                        >
                                                            Send
                                                        </button>
                                                    ) : (
                                                        <button disabled className="btn btn-primary login-btn" style={{ background: headerButtonColor }}>
                                                            Sending.. <i className="fa fa-spinner fa-spin validat"></i>
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <Rightnav />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Profile;
