import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../coreFIles/config'
import new_image from '../Assets/new.gif'
import toast, { Toaster } from 'react-hot-toast';
import { getsidebarMenuAction, getbannerAdsAction, UpgradeAccountAction, memberloginwithfacebookAction } from '../Action/action';
import Cookies from 'js-cookie'
import FacebookLogin from 'react-facebook-login';
const Domainstyle = (!Cookies.get('domainstyle')) ? [] : JSON.parse(Cookies.get('domainstyle'));
const Leftsidebar = () => {
	// style

	const headerButtonColor = `#${Domainstyle[0]?.header_buttoncolor}`;
	const headerMenuColor = `#${Domainstyle[0]?.header_menu}`;
	const headerMenuHoverColor = `#${Domainstyle[0]?.header_menu_hover}`;
	const header_search_text = `#${Domainstyle[0]?.header_search_text}`
	// style end
	const loginData = (!Cookies.get('loginSuccessMember')) ? [] : JSON.parse(Cookies.get('loginSuccessMember'));
	const [sidebarMenu, setsidebarMenu] = useState({});
	const [bannerAds, setbannerAds] = useState({});
	const [facebooklogin, setfacebooklogin] = useState("0")
	useEffect(() => {
		getsidebarMenu();
		getbannerAds();
	}, [])
	useEffect(() => {
		let action = loginData.action
		if (action == "fbLogin") {
			setfacebooklogin("1")
		}
	}, [])

	const getsidebarMenu = async () => {
		let res = await getsidebarMenuAction({ keyword: 'footer' });
		if (res.success) {
			setsidebarMenu(res.data);
		}
	};

	const getbannerAds = async () => {
		let res = await getbannerAdsAction({ adColumn: 'L' });
		if (res.success) {
			setbannerAds(res.data);
		}
	};

	const handleContentUrl = (id) => {
		var pagenewUrl = '';
		if (id == 1) {
			pagenewUrl = config.baseUrl + 'about'
		}
		else if (id == 2) {
			pagenewUrl = config.baseUrl + 'advertise-with-us'
		}
		else if (id == 3) {
			pagenewUrl = config.baseUrl + 'help'
		}
		else if (id == 5) {
			pagenewUrl = config.baseUrl + 'terms-and-conditions'
		}
		else if (id == 6) {
			pagenewUrl = config.baseUrl + 'privacy-policy'
		}
		window.location.href = `${pagenewUrl}`;
	};
	const formatDate = (str) => {
		const date = new Date(str);
		const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
		const day = ("0" + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join("-");
	};

	const adddaysToDate = (days) => {
		const cdate = new Date();
		const result = cdate.setDate(cdate.getDate() + days);
		return formatDate(result);
	};
	const upgrademembership = async () => {

		if (loginData) {
			const fromDate = formatDate(new Date());
			const res = await UpgradeAccountAction({ memberid: loginData.id, current_date: fromDate })
			if (res.success) {
				toast.success(res.msg);
				// setInterval(() => {
				//     window.location.href=`${config.baseUrl}`
				// }, 2000);
			}


		}

	}
	// for facebook login 
	const responseFacebook = async (response) => {
		let res = await memberloginwithfacebookAction(response)
		if (res.success) {
			toast.success(res.msg)
			Cookies.set('loginSuccessMember', JSON.stringify(res.data));
			setTimeout(() => {
				window.location.href = `${config.baseUrl}`;
			}, 1200);			

		}
		else {
			toast.error(res.msg)
		}
	}
	const handleLogout = () => {
		setfacebooklogin("0")
		console.log(facebooklogin)
		Cookies.remove("loginSuccessMember");
		window.location.href = config.baseUrl;
	};
	return (
		<>
			<Toaster />
			<div className="section-member">
				<div className="btnpost">
					{!loginData || loginData == '' ?
						<Link to={config.baseUrl + 'login'} style={{ background: headerMenuColor }}><span><img src={config.baseUrl + "img/ads.png"} alt='ads' /></span>Post Your Ad</Link>
						:
						<Link to={config.baseUrl + 'addad'} style={{ background: headerMenuColor }}><span><img src={config.baseUrl + "img/ads.png"} alt='ads' /></span>Post Your Ad</Link>
					}
				</div>
				{!loginData || loginData == '' ?
					<div className="btnpost">
						<Link to={config.baseUrl + 'login'} style={{ background: headerMenuColor }}><span><i className="fa-solid fa-arrow-right-to-bracket"></i></span>Member Login</Link>
					</div>
					:
					<div className="btnpost">
						<Link to={config.baseUrl + 'manage-ads'} style={{ background: headerMenuColor }}><span><i className="fa-solid fa-arrow-right-to-bracket"></i></span>Manage Ads</Link>
						<Link to={config.baseUrl + 'SearchHistory'} style={{ background: headerMenuColor }}><span><i className="fa-solid fa-search"></i></span>Search History</Link>
						<Link to={config.baseUrl + 'MarkfavouriteAd'} style={{ background: headerMenuColor }}><span><i className="fa-solid fa-heart"></i></span>Favourite Ads</Link>
					</div>
				}

				<div className="domorecor-logo">
					<img src={config.baseUrl + "img/domorecor.png"} alt='do more' />
				</div>
				<div  className="btnpost" >
					{
						facebooklogin === "0" ?
							<FacebookLogin
								cssClass="btnFacebook"								
								appId="986705326547259"
								autoLoad={false}
								fields="name,email,picture"
								callback={responseFacebook}
								icon="fa fa-facebook"
								textButton="&nbsp;&nbsp;Login with Facebook"
								
							/>
							: ""}
					{
						facebooklogin === "1" ?
							<Link to="" onClick={handleLogout} style={{background:headerButtonColor}}><span ><i className="fa-brands fa-facebook-f"></i></span>Logout</Link> :
							""
					}

				</div>
				<div className="about-section">
					<ul>
						<h4 style={{ background: headerMenuColor }}>MENU</h4>
						{sidebarMenu.length > 0 ?
							sidebarMenu.map((row) => (
								<li><Link to="javascript:;" onClick={() => handleContentUrl(row.content_id)}>{row.title}</Link></li>
							))
							: ''}
						{!loginData || loginData == '' ?
							(
								<li><Link to={config.baseUrl + 'vipmembership'}>
									VIP MEMBERSHIP</Link><img src={new_image} style={{ marginLeft: "15px" }} alt="Connection error" /></li>
							)
							:
							(
								<li><Link to={config.baseUrl + 'vipmembership'}>VIP MEMBERSHIP</Link><img src={new_image} style={{ marginLeft: "15px" }} alt="Connection error" /></li>
							)
						}
						<li><Link to={config.baseUrl + 'sharewithfriend'}>TELL A FRIEND</Link></li>
						<li><Link to={config.baseUrl + 'contact-us'}>CONTACT US</Link></li>

						{/* <li><Link to="">ABOUT US</Link></li>
						<li><Link to="">ADVERTISE WITH US</Link></li>
						<li><Link to="">VIP MEMBERSHIP</Link></li>
						<li><Link to="">TELL A FRIEND</Link></li>
						<li><Link to="">HELP</Link></li>
						<li><Link to="">CONTACT US</Link></li> */}
					</ul>
				</div>
				<div className="towblock">
					{bannerAds.length > 0 ? (
						bannerAds.map((row) => (
							<div className="heroimg">
								{row.ad_type == 'img' ?
									<Link to={row.ad_url} target='_blank'>
										<img src={config.bannersUrl + row.ad_file} alt={row.ad_title} />
									</Link>
									: ''}
							</div>
						))
					) : ''}
				</div>
			</div>
		</>
	)
}

export default Leftsidebar;
